import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Percent, TradeType } from '@sushiswap/core-sdk'
import Button from 'app/components/Button'
import { CurrencyLogo } from 'app/components/CurrencyLogo'
import HeadlessUiModal from 'app/components/Modal/HeadlessUIModal'
import Typography from 'app/components/Typography'
import SwapDetails from 'app/features/legacy/swap/SwapDetails'
import { useUSDCValue } from 'app/hooks/useUSDCPrice'
import { TradeUnion } from 'app/types'
import Image from 'next/image'
import React, { FC } from 'react'

interface SwapModalHeader {
  trade?: TradeUnion
  allowedSlippage: Percent
  recipient?: string
  showAcceptChanges: boolean
  onAcceptChanges: () => void
}

const SwapModalHeader: FC<SwapModalHeader> = ({
  trade,
  allowedSlippage,
  recipient,
  showAcceptChanges,
  onAcceptChanges,
}) => {
  const { i18n } = useLingui()
  const fiatValueInput = useUSDCValue(trade?.inputAmount)
  const fiatValueOutput = useUSDCValue(trade?.outputAmount)

  const change =
    ((Number(fiatValueOutput?.toExact()) - Number(fiatValueInput?.toExact())) / Number(fiatValueInput?.toExact())) * 100

  return (
    <div className="grid gap-2">
      <div className="flex flex-col">
        <div className="flex items-center gap-3">
          <HeadlessUiModal.BorderedContent className="bg-dark-1000/40 border !border-dark-800 ">
            <div className="flex items-start justify-between">
              {/* <div className="flex items-start gap-3">
              <div className="flex flex-col gap-1"></div>
            </div> */}
              <div className="flex items-center gap-3">
                <CurrencyLogo
                  currency={trade?.inputAmount.currency}
                  size={18}
                  className="!rounded-full overflow-hidden"
                />
                <Typography className="text-high-emphesis">{trade?.inputAmount.toSignificant(6)} </Typography>
                {/* {fiatValueInput?.greaterThan(ZERO) && (
                <Typography className="text-secondary">${fiatValueInput.toFixed(2)}</Typography>
              )} */}

                <Typography className="text-high-emphesis">{trade?.inputAmount.currency.symbol}</Typography>
              </div>
            </div>
          </HeadlessUiModal.BorderedContent>
          <div className="flex justify-center -mt-3 -mb-3">
            <div className="">
              <Image
                src="/images/arrow.svg"
                alt="swapicon"
                // layout="responsive"
                width="14"
                height="14"
                className="ArrowIcon"
              />
            </div>
          </div>
          <HeadlessUiModal.BorderedContent className="bg-dark-1000/40 border !border-dark-800">
            <div className="flex items-start justify-between">
              <div className="flex items-start gap-3">
                <div className="flex flex-col gap-1">
                  {/* <Typography variant="h3" weight={700} className="text-high-emphesis">
                  {trade?.outputAmount.toSignificant(6)}{' '}
                </Typography> */}
                  {/* {fiatValueOutput?.greaterThan(ZERO) && (
                  <Typography className="text-secondary">
                    ${fiatValueOutput?.toFixed(2)}{' '}
                    <Typography variant="xs" component="span">
                      ({change.toFixed(2)}%)
                    </Typography>
                  </Typography>
                )} */}
                </div>
              </div>
              <div className="flex items-center gap-3">
                <CurrencyLogo
                  currency={trade?.outputAmount.currency}
                  size={18}
                  className="!rounded-full overflow-hidden"
                />
                <Typography className="text-high-emphesis">{trade?.outputAmount.toSignificant(6)} </Typography>
                <Typography className="text-high-emphesis">{trade?.outputAmount.currency.symbol}</Typography>
              </div>
            </div>
          </HeadlessUiModal.BorderedContent>
        </div>
      </div>
      <SwapDetails
        trade={trade}
        recipient={recipient}
        inputCurrency={trade?.inputAmount.currency}
        outputCurrency={trade?.outputAmount.currency}
        className=""
      />

      {showAcceptChanges && (
        <HeadlessUiModal.BorderedContent className="border !border-dark-800">
          <div className="flex items-center justify-between">
            <Typography variant="sm" weight={700}>
              {i18n._(t`Price Updated`)}
            </Typography>
            <Button variant="outlined" size="xs" color="blue" onClick={onAcceptChanges}>
              {i18n._(t`Accept`)}
            </Button>
          </div>
        </HeadlessUiModal.BorderedContent>
      )}
      <div className="justify-start text-sm text-center text-secondary py-2">
        {trade?.tradeType === TradeType.EXACT_INPUT ? (
          <Typography variant="xs" className="text-secondary">
            {i18n._(t`Output is estimated. You will receive at least`)}{' '}
            <Typography variant="xs" className="text-high-emphesis" weight={700} component="span">
              {trade.minimumAmountOut(allowedSlippage).toSignificant(6)} {trade.outputAmount.currency.symbol}
            </Typography>{' '}
            {i18n._(t`or the transaction will revert.`)}
          </Typography>
        ) : (
          <Typography variant="xs" className="text-secondary">
            {i18n._(t`Input is estimated. You will sell at most`)}{' '}
            <Typography variant="xs" className="text-high-emphesis" weight={700} component="span">
              {trade?.maximumAmountIn(allowedSlippage).toSignificant(6)} {trade?.inputAmount.currency.symbol}
            </Typography>{' '}
            {i18n._(t`or the transaction will revert.`)}
          </Typography>
        )}
      </div>
    </div>
  )
}

export default SwapModalHeader
